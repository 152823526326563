import {
	typVHookAnyAnyAny,
	typVHookAnyAny,
	lenVHookAnyAnyAny,
	lenVHookAnyAny,
} from './utils';
import { Expr, FuncDict } from 'src/types';
import { intersectTyp } from '../utils';

// OpsVal
const cond = (v1: any, v2: any, v3: any) => ({ $cond: [v1, v2, v3] });
const ifNull = (v1: any, v2: any) => ({ $ifNull: [v1, v2] });

const conditionalOps: FuncDict = {
	COND: {
		val: (e1: Expr, e2: Expr, e3: Expr) => cond(e1.val, e2.val, e3.val),
		typ: (e1: Expr, e2: Expr, e3: Expr) => intersectTyp(e2.typ, e3.typ),
		argsHook: typVHookAnyAnyAny,
		callHook: lenVHookAnyAnyAny,
	},
	IFNULL: {
		val: (e1: Expr, e2: Expr) => ifNull(e1.val, e2.val),
		typ: (e1: Expr, e2: Expr) => intersectTyp(e1.typ, e2.typ),
		argsHook: typVHookAnyAny,
		callHook: lenVHookAnyAny,
	},
};

export default conditionalOps;
export { cond, ifNull };
