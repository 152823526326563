import {
	dateTyp,
	stringTyp,
	numberTyp,
	typVHookDSo,
	typVHookDSoSo,
	typVHookSSoSo,
	lenVHookDSo,
	lenVHookDSoSo,
	lenVHookSSoSo,
	createValidationHook,
	createRequiredPropertyHook,
} from './utils';
import { add, multiply } from './arithmeticOps';
import { FuncDict, Expr, Typ, FuncScope, FuncSignature } from 'src/types';
import { mergeObjects } from './objectOps';
import { isSubTyp } from '../utils';

const partsTyp: Typ = {
	type: 'object',
	properties: {
		year: { type: 'number' },
		month: { type: 'number' },
		day: { type: 'number' },
		hour: { type: 'number' },
		minute: { type: 'number' },
		second: { type: 'number' },
		millisecond: { type: 'number' },
	},
};
const sigPartsSo: FuncSignature = {
	argsTyp: [partsTyp, { type: 'string' }],
	minArgsCount: 1,
	maxArgsCount: 2,
};
const {
	typValidationHook: typVHookPartsSo,
	lenValidationHook: lenVHookPartsSo,
} = createValidationHook(sigPartsSo, isSubTyp);
const yearRequiredHook = createRequiredPropertyHook(0, 'year');
const isoPartsTyp: Typ = {
	type: 'object',
	properties: {
		isoWeekYear: { type: 'number' },
		isoWeek: { type: 'number' },
		isoDayOfWeek: { type: 'number' },
		hour: { type: 'number' },
		minute: { type: 'number' },
		second: { type: 'number' },
		millisecond: { type: 'number' },
	},
};
const sigIsoPartsSo: FuncSignature = {
	argsTyp: [isoPartsTyp, { type: 'string' }],
	minArgsCount: 1,
	maxArgsCount: 2,
};
const {
	typValidationHook: typVHookIsoPartsSo,
	lenValidationHook: lenVHookIsoPartsSo,
} = createValidationHook(sigIsoPartsSo, isSubTyp);
const isoWeekYearRequiredHook = createRequiredPropertyHook(0, 'isoWeekYear');

// OpsVal
const dateFromParts = (v0: any, v1?: any) => {
	if (v1 !== undefined)
		return {
			$dateFromParts: mergeObjects([v0, { timezone: v1 }]),
		};
	return {
		$dateFromParts: v0,
	};
};
const isoDateFromParts = dateFromParts;
const dateToParts = (v0: any, v1?: any) => ({
	$dateToParts: {
		date: v0,
		...(v1 !== undefined ? { timezone: v1 } : undefined),
	},
});
const isoDateToParts = (v0: any, v1?: any) => ({
	$dateToParts: {
		date: v0,
		iso8601: true,
		...(v1 !== undefined ? { timezone: v1 } : undefined),
	},
});
const dateFromString = (v0: any, v1?: any, v2?: any) => ({
	$dateFromString: {
		dateString: v0,
		onError: null,
		onNull: null,
		...(v1 !== undefined ? { format: v1 } : undefined),
		...(v2 !== undefined ? { timezone: v2 } : undefined),
	},
});
const dateToString = (v0: any, v1?: any, v2?: any) => ({
	$dateToString: {
		date: v0,
		onNull: null,
		...(v1 !== undefined ? { format: v1 } : undefined),
		...(v2 !== undefined ? { timezone: v2 } : undefined),
	},
});
const year = (v0: any, v1?: any) =>
	v1 === undefined ? { $year: v0 } : { $year: { date: v0, timezone: v1 } };
const month = (v0: any, v1?: any) =>
	v1 === undefined ? { $month: v0 } : { $month: { date: v0, timezone: v1 } };
const week = (v0: any, v1?: any) =>
	v1 === undefined ? { $week: v0 } : { $week: { date: v0, timezone: v1 } };
const dayOfYear = (v0: any, v1?: any) =>
	v1 === undefined
		? { $dayOfYear: v0 }
		: { $dayOfYear: { date: v0, timezone: v1 } };
const dayOfMonth = (v0: any, v1?: any) =>
	v1 === undefined
		? { $dayOfMonth: v0 }
		: { $dayOfMonth: { date: v0, timezone: v1 } };
const dayOfWeek = (v0: any, v1?: any) =>
	v1 === undefined
		? { $dayOfWeek: v0 }
		: { $dayOfWeek: { date: v0, timezone: v1 } };
const hour = (v0: any, v1?: any) =>
	v1 === undefined ? { $hour: v0 } : { $hour: { date: v0, timezone: v1 } };
const minute = (v0: any, v1?: any) =>
	v1 === undefined
		? { $minute: v0 }
		: { $minute: { date: v0, timezone: v1 } };
const second = (v0: any, v1?: any) =>
	v1 === undefined
		? { $second: v0 }
		: { $second: { date: v0, timezone: v1 } };
const millisecond = (v0: any, v1?: any) =>
	v1 === undefined
		? { $millisecond: v0 }
		: { $millisecond: { date: v0, timezone: v1 } };
const isoWeekYear = (v0: any, v1?: any) =>
	v1 === undefined
		? { $isoWeekYear: v0 }
		: { $isoWeekYear: { date: v0, timezone: v1 } };
const isoWeek = (v0: any, v1: any) =>
	v1 === undefined
		? { $isoWeek: v0 }
		: { $isoWeek: { date: v0, timezone: v1 } };
const isoDayOfWeek = (v0: any, v1: any) =>
	v1 === undefined
		? { $isoDayOfWeek: v0 }
		: { $isoDayOfWeek: { date: v0, timezone: v1 } };
const millisecondOfDay = (v0: any, v1: any) => {
	const millisecondOfDayInV = add(
		multiply('$$parts___.hour', 3600000),
		multiply('$$parts___.minute', 60000),
		multiply('$$parts___.second', 1000),
		'$$parts___.millisecond'
	);
	return {
		$let: {
			vars: { parts___: dateToParts(v0, v1) },
			in: millisecondOfDayInV,
		},
	};
};

const dateOps: FuncDict = {
	DATEFROMPARTS: {
		val: (e0: Expr, e1?: Expr) =>
			dateFromParts(e0.val, e1 ? e1.val : undefined),
		typ: dateTyp,
		argsHook: (funcScope: FuncScope) => {
			typVHookPartsSo(funcScope);
			yearRequiredHook(funcScope);
		},
		callHook: lenVHookPartsSo,
	},
	DATETOPARTS: {
		val: (e0: Expr, e1?: Expr) =>
			dateToParts(e0.val, e1 ? e1.val : undefined),
		typ: () => partsTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	ISODATEFROMPARTS: {
		val: (e0: Expr, e1?: Expr) =>
			isoDateFromParts(e0.val, e1 ? e1.val : undefined),
		typ: dateTyp,
		argsHook: (funcScope: FuncScope) => {
			typVHookIsoPartsSo(funcScope);
			isoWeekYearRequiredHook(funcScope);
		},
		callHook: lenVHookIsoPartsSo,
	},
	ISODATETOPARTS: {
		val: (e0: Expr, e1?: Expr) =>
			isoDateToParts(e0.val, e1 ? e1.val : undefined),
		typ: () => isoPartsTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	DATEFROMSTRING: {
		val: (e0: Expr, e1?: Expr, e2?: Expr) =>
			dateFromString(
				e0.val,
				e1 ? e1.val : undefined,
				e2 ? e2.val : undefined
			),
		typ: dateTyp,
		argsHook: typVHookSSoSo,
		callHook: lenVHookSSoSo,
	},
	DATETOSTRING: {
		val: (e0: Expr, e1?: Expr, e2?: Expr) =>
			dateToString(
				e0.val,
				e1 ? e1.val : undefined,
				e2 ? e2.val : undefined
			),
		typ: stringTyp,
		argsHook: typVHookDSoSo,
		callHook: lenVHookDSoSo,
	},
	YEAR: {
		val: (e0: Expr, e1?: Expr) => year(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	MONTH: {
		val: (e0: Expr, e1?: Expr) => month(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	WEEK: {
		val: (e0: Expr, e1?: Expr) => week(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	DAYOFYEAR: {
		val: (e0: Expr, e1?: Expr) =>
			dayOfYear(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	DAYOFMONTH: {
		val: (e0: Expr, e1?: Expr) =>
			dayOfMonth(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	DAYOFWEEK: {
		val: (e0: Expr, e1?: Expr) =>
			dayOfWeek(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	HOUR: {
		val: (e0: Expr, e1?: Expr) => hour(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	MINUTE: {
		val: (e0: Expr, e1?: Expr) => minute(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	SECOND: {
		val: (e0: Expr, e1?: Expr) => second(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	MILLISECOND: {
		val: (e0: Expr, e1?: Expr) =>
			millisecond(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	ISOWEEKYEAR: {
		val: (e0: Expr, e1?: Expr) =>
			isoWeekYear(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	ISOWEEK: {
		val: (e0: Expr, e1?: Expr) => isoWeek(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	ISODAYOFWEEK: {
		val: (e0: Expr, e1?: Expr) =>
			isoDayOfWeek(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
	MILLISECONDOFDAY: {
		val: (e0: Expr, e1?: Expr) =>
			millisecondOfDay(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookDSo,
		callHook: lenVHookDSo,
	},
};

export default dateOps;
export {
	dateFromParts,
	isoDateFromParts,
	dateToParts,
	isoDateToParts,
	dateFromString,
	dateToString,
	year,
	month,
	week,
	dayOfYear,
	dayOfMonth,
	dayOfWeek,
	hour,
	minute,
	second,
	millisecond,
	isoWeekYear,
	isoWeek,
	isoDayOfWeek,
	millisecondOfDay,
};
