import { Parser } from './parser/formulaParser';
import formulaLexer from './lexer/formulaLexer';
import defaultFuncDict from './ops';
import { YY, FuncDict, ParserSchema, ParserOutput, Expr, Err } from './types';
import Lexer from './lexer/Lexer';
import { _Error, ParseError, KeyError } from './utils';

export default class FormulaParser extends Parser {
	private yy: YY;
	private lexer: Lexer;

	constructor(funcDict: FuncDict = defaultFuncDict) {
		super();
		this.yy = {
			funcDict,
			scopeStack: [{ name: 'ROOT', schema: {} }],
			nextScope: null,
			dependencies: [],
		};
		this.lexer = formulaLexer;
	}

	listFuncKeys() {
		return Object.keys(this.yy.funcDict);
	}

	listVarKeys() {
		const combinedSchema = this.yy.scopeStack.reduce(
			(schema, scope) => Object.assign(schema, scope.schema),
			{} as ParserSchema
		);
		return Object.keys(combinedSchema);
	}

	getFunc(funcKey: string) {
		const funcDict = this.yy.funcDict;
		if (!funcDict.hasOwnProperty(funcKey))
			throw new KeyError({
				key: funcKey,
				keyType: 'function',
				loc: null,
				reason: 'Function is not found.',
			});
		return this.yy.funcDict[funcKey];
	}

	getVarTyp(varKey: string) {
		const combinedSchema = this.yy.scopeStack.reduce(
			(schema, scope) => Object.assign(schema, scope.schema),
			{} as ParserSchema
		);
		if (!combinedSchema.hasOwnProperty(varKey))
			throw new KeyError({
				key: varKey,
				keyType: 'variable',
				loc: null,
				reason: 'Variable is not found.',
			});
		return combinedSchema[varKey];
	}

	private parseError(
		message: string,
		hash: {
			token: string;
			expected: string[];
		}
	) {
		const { token, expected } = hash;
		throw new ParseError({
			receivedToken: token,
			expectedTokens: expected.map((token) => token.slice(1, -1)),
		});
	}

	parse(input: string, rootSchema: ParserSchema = {}): ParserOutput {
		this.yy.scopeStack = [{ name: 'ROOT', schema: rootSchema }];
		this.yy.dependencies = [];
		try {
			const expr: Expr = super.parse(input);
			return {
				expr,
				info: {
					dependencies: this.yy.dependencies,
					scopeStack: this.yy.scopeStack,
					tokenInfoStack: this.lexer.tokenInfoStack,
				},
			};
		} catch (error) {
			const err: Err =
				error instanceof _Error
					? error.err
					: { name: 'Err', details: {} };
			return {
				err,
				info: {
					dependencies: this.yy.dependencies,
					scopeStack: this.yy.scopeStack,
					tokenInfoStack: this.lexer.tokenInfoStack,
				},
			};
		}
	}
}
