import {
	booleanTyp,
	typVHookAny,
	typVHookAnyAnyAnys,
	lenVHookAny,
	lenVHookAnyAnyAnys,
} from './utils';
import { Expr, FuncDict } from 'src/types';

// OpsVal
const and = (...vs: any[]) => {
	const _vs: any[] = [];
	vs.forEach((v) => {
		if (v !== null && v.hasOwnProperty('$and')) _vs.push(...v.$and);
		else _vs.push(v);
	});
	return { $and: [..._vs] };
};
const or = (...vs: any[]) => {
	const _vs: any[] = [];
	vs.forEach((v) => {
		if (v !== null && v.hasOwnProperty('$or')) _vs.push(...v.$or);
		else _vs.push(v);
	});
	return { $or: [..._vs] };
};
const not = (v: any) => ({ $not: v });

const booleanOps: FuncDict = {
	AND: {
		val: (...es: Expr[]) => and(...es.map((e) => e.val)),
		typ: booleanTyp,
		argsHook: typVHookAnyAnyAnys,
		callHook: lenVHookAnyAnyAnys,
	},
	OR: {
		val: (...es: Expr[]) => or(...es.map((e) => e.val)),
		typ: booleanTyp,
		argsHook: typVHookAnyAnyAnys,
	},
	NOT: {
		val: (e: Expr) => not(e.val),
		typ: booleanTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
};

export default booleanOps;
export { and, or, not };
