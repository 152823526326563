import { TokenInfo } from 'src/types';
import { LexError } from '../utils';

interface TokenVal {
	token: string;
	val: any;
}

export type Action = (lexeme: string) => TokenVal | void;

export interface Rule {
	pattern: RegExp;
	action: Action;
}

function _defaultAction(lexeme?: string) {
	if (lexeme) return { token: lexeme, val: undefined };
}

function _errAction(this: Lexer) {
	throw new LexError({ pos: this.pos, char: this.input[this.pos] });
}

export default class Lexer {
	rules: Rule[];
	tokenInfoStack: TokenInfo[];
	input: string;
	pos: number;
	yytext: any; // the value corresponding to the token
	yylloc: {
		first_line: number;
		last_line: number;
		first_column: number;
		last_column: number;
	};

	constructor() {
		this.rules = [];
		this.tokenInfoStack = [];
		this.pos = 0;
		this.input = '';
		this.yytext = null;
		this.yylloc = {
			first_line: 0,
			last_line: 1,
			first_column: 0,
			last_column: 0,
		};
		this.addRule(/\n/, function(this: Lexer) {
			this.yylloc = {
				first_line: this.yylloc.first_line + 1,
				last_line: this.yylloc.last_line + 1,
				first_column: 0,
				last_column: 0,
			};
		});
	}

	setInput = (input: string): void => {
		this.tokenInfoStack = [];
		this.input = input;
		this.pos = 0;
		this.yytext = null;
		this.yylloc = {
			first_line: 0,
			last_line: 1,
			first_column: 0,
			last_column: 0,
		};
	};

	addRule = (pattern: RegExp, action: Action = _defaultAction): void => {
		let flags = 'g';
		if (pattern.multiline) flags += 'm';
		if (pattern.ignoreCase) flags += 'i';
		pattern = new RegExp(pattern.source, flags);
		this.rules.push({
			pattern,
			action,
		});
	};

	lex(): string | undefined {
		function findNextMatch(input: string, rules: Rule[], pos: number) {
			// return longest match that appears first
			let longestMatch: { lexeme?: string; action: Action } = {
				lexeme: undefined,
				action: _errAction,
			};
			for (let i = 0, l = rules.length; i < l; i++) {
				const { pattern, action } = rules[i];
				pattern.lastIndex = pos;
				const result = pattern.exec(input);
				if (result && result.index === pos) {
					const lexeme = result[0];
					if (
						!longestMatch.lexeme ||
						lexeme.length > longestMatch.lexeme.length
					) {
						longestMatch = { lexeme: lexeme, action };
					}
				}
			}
			return longestMatch;
		}
		while (this.pos <= this.input.length) {
			const { input, rules, pos } = this;
			const { lexeme, action } = findNextMatch(input, rules, pos);
			const lexemeLength = lexeme ? lexeme.length : 0;
			const tokenVal = action.call(this, lexeme || '');
			const nextPos = pos + (lexemeLength ? lexemeLength : 1);
			this.pos = nextPos;
			this.yylloc = {
				first_line: this.yylloc.first_line,
				last_line: this.yylloc.last_line,
				first_column: this.yylloc.last_column,
				last_column: this.yylloc.last_column + lexemeLength,
			};
			if (tokenVal) {
				const { token, val } = tokenVal;
				this.yytext = val;
				this.tokenInfoStack.push({
					token,
					lexeme: lexeme || '',
					val,
					pos,
					loc: this.yylloc,
				});
				return token;
			}
		}
	}
}
