import { FuncDict } from 'src/types';
import accumulatorOps from './accumulatorOps';
import arithmeticOps from './arithmeticOps';
import arrayOps from './arrayOps';
import booleanOps from './booleanOps';
import comparisonOps from './comparisonOps';
import conditionalOps from './conditionalOps';
import dateOps from './dateOps';
import objectOps from './objectOps';
import setOps from './setOps';
import stringOps from './stringOps';
import typeOps from './typeOps';
import miscOps from './miscOps';

const defaultFuncDict: FuncDict = {
	...accumulatorOps,
	...arithmeticOps,
	...arrayOps,
	...booleanOps,
	...comparisonOps,
	...conditionalOps,
	...objectOps,
	...setOps,
	...stringOps,
	...dateOps,
	...typeOps,
	...miscOps,
};

export default defaultFuncDict;
