import {
	booleanTyp,
	numberTyp,
	stringTyp,
	dateTyp,
	typVHookAny,
	lenVHookAny,
} from './utils';
import { FuncDict, Expr } from 'src/types';

// OpsVal
const toBool = (v: any) => ({
	$convert: { input: v, to: 'bool', onError: null },
});
const toDate = (v: any) => ({
	$convert: { input: v, to: 'date', onError: null },
});
const toDecimal = (v: any) => ({
	$convert: { input: v, to: 'decimal', onError: null },
});
const toDouble = (v: any) => ({
	$convert: { input: v, to: 'double', onError: null },
});
const toInt = (v: any) => ({
	$convert: { input: v, to: 'int', onError: null },
});
const toLong = (v: any) => ({
	$convert: { input: v, to: 'long', onError: null },
});
const toString = (v: any) => ({
	$convert: { input: v, to: 'string', onError: null },
});
const type = (v: any) => ({ $type: v });

const typeOps: FuncDict = {
	TOBOOL: {
		val: (e: Expr) => toBool(e.val),
		typ: booleanTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
	TODATE: {
		val: (e: Expr) => toDate(e.val),
		typ: dateTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
	TODECIMAL: {
		val: (e: Expr) => toDecimal(e.val),
		typ: numberTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
	TODOUBLE: {
		val: (e: Expr) => toDouble(e.val),
		typ: numberTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
	TOINT: {
		val: (e: Expr) => toInt(e.val),
		typ: numberTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
	TOLONG: {
		val: (e: Expr) => toLong(e.val),
		typ: numberTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
	TOSTRING: {
		val: (e: Expr) => toString(e.val),
		typ: stringTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
	TYPE: {
		val: (e) => type(e.val),
		typ: stringTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
};

export default typeOps;
export { toBool, toDate, toDecimal, toDouble, toInt, toLong, toString, type };
