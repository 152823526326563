import {
	typVHookN,
	typVHookNN,
	typVHookNNo,
	typVHookNNNs,
	typVHookNNNs_DN,
	typVHookNN_DN_DD,
	lenVHookN,
	lenVHookNN,
	lenVHookNNo,
	lenVHookNNNs,
	lenVHookNNNs_DN,
	lenVHookNN_DN_DD,
	numberTyp,
	arg0Typ,
} from './utils';
import { cmp, gt, gte, eq } from './comparisonOps';
import { cond } from './conditionalOps';
import { FuncDict, Expr, Typ } from 'src/types';

// TypeOps
const _toInt = (v: any) => ({ $toInt: v });

// OpsTyp
const subtractTyp = (e0: Expr, e1: Expr): Typ => {
	let typ: Typ = { type: 'undetermined' };
	const t0 = e0.typ;
	const t1 = e1.typ;
	if (t0.type === 'number' || t0.type === 'date') {
		if (t0.type === t1.type) typ = { type: 'number' };
		else if (t1.type === 'number' || t1.type === 'date')
			typ = { type: 'date' };
	}
	return typ;
};

// OpsVal
const abs = (v: any) => ({ $abs: v });
const add = (...vs: any[]) => {
	const _vs: any[] = [];
	vs.forEach((v) => {
		if (v !== null && v.hasOwnProperty('$add')) _vs.push(...v.$add);
		else _vs.push(v);
	});
	return { $add: [..._vs] };
};
const subtract = (v0: any, v1: any) => ({ $subtract: [v0, v1] });
const multiply = (...vs: any[]) => {
	const _vs: any[] = [];
	vs.forEach((v) => {
		if (v !== null && v.hasOwnProperty('$multiply'))
			_vs.push(...v.$multiply);
		else _vs.push(v);
	});
	return { $multiply: [..._vs] };
};
const divide = (v0: any, v1: any) => ({ $divide: [v0, v1] });
const neg = (v: any) => multiply(-1, v);
const pow = (v0: any, v1: any) => ({ $pow: [v0, v1] });
const ceil = (v: any) => ({ $ceil: v });
const floor = (v: any) => ({ $floor: v });
const log = (v0: any, v1: any) => ({ $log: [v0, v1] });
const log10 = (v: any) => ({ $log10: v });
const ln = (v: any) => ({ $ln: v });
const exp = (v: any) => ({ $exp: v });
const mod = (v0: any, v1: any) => ({ $mod: [v0, v1] });
const sqrt = (v: any) => ({ $sqrt: v });
const trunc = (v: any) => ({ $trunc: v });
const sign = (v: any) => cmp(v, 0);
const _safeDivideInV = cond(
	eq('$$divisor___', 0),
	null,
	divide('$$dividend___', '$$divisor___')
);
const safeDivide = (v0: any, v1: any) => {
	return {
		$let: {
			vars: { dividend___: v0, divisor___: v1 },
			in: _safeDivideInV,
		},
	};
};
const quotient = (v0: any, v1: any) => trunc(safeDivide(v0, v1));

const _roundIntInV = {
	$let: {
		vars: { magn___: abs('$$num___'), sign___: sign('$$num___') },
		in: {
			$let: {
				vars: { trunced___: trunc('$$magn___') },
				in: multiply(
					'$$sign___',
					add(
						'$$trunced___',
						_toInt(
							gte(subtract('$$magn___', '$$trunced___'), 1 / 2)
						)
					)
				),
			},
		},
	},
};
const round = (v0: any, v1: any) => {
	const roundInV = {
		$let: {
			vars: { m___: pow(10, abs('$$dp___')) },
			in: cond(
				gte('$$dp___', 0),
				divide(
					{
						$let: {
							vars: { num___: multiply('$$num___', '$$m___') },
							in: _roundIntInV,
						},
					},
					'$$m___'
				),
				multiply(
					{
						$let: {
							vars: { num___: divide('$$num___', '$$m___') },
							in: _roundIntInV,
						},
					},
					'$$m___'
				)
			),
		},
	};
	if (v1 !== undefined)
		return {
			$let: { vars: { num___: v0, dp___: trunc(v1) }, in: roundInV },
		};
	return { $let: { vars: { num___: v0 }, in: _roundIntInV } };
};
const mround = (v0: any, v1: any) => {
	return {
		$let: {
			vars: { num___: v0, m___: v1 },
			in: cond(
				gt('$$m___', 0),
				multiply(
					{
						$let: {
							vars: { num___: divide('$$num___', '$$m___') },
							in: _roundIntInV,
						},
					},
					'$$m___'
				),
				null
			),
		},
	};
};
const _roundIntUpInV = {
	$let: {
		vars: { magn___: abs('$$num___'), sign___: sign('$$num___') },
		in: multiply('$$sign___', ceil('$$magn___')),
	},
};
const roundUp = (v0: any, v1: any) => {
	const roundUpInV = {
		$let: {
			vars: { m___: pow(10, abs('$$dp___')) },
			in: cond(
				gte('$$dp___', 0),
				divide(
					{
						$let: {
							vars: { num___: multiply('$$num___', '$$m___') },
							in: _roundIntUpInV,
						},
					},
					'$$m___'
				),
				multiply(
					{
						$let: {
							vars: { num___: divide('$$num___', '$$m___') },
							in: _roundIntUpInV,
						},
					},
					'$$m___'
				)
			),
		},
	};
	if (v1 !== undefined)
		return {
			$let: { vars: { num___: v0, dp___: trunc(v1) }, in: roundUpInV },
		};
	return { $let: { vars: { num___: v0 }, in: _roundIntUpInV } };
};
const mroundUp = (v0: any, v1: any) => {
	return {
		$let: {
			vars: { num___: v0, m___: v1 },
			in: cond(
				gt('$$m___', 0),
				multiply(
					{
						$let: {
							vars: { num___: divide('$$num___', '$$m___') },
							in: _roundIntUpInV,
						},
					},
					'$$m___'
				),
				null
			),
		},
	};
};

const _roundIntDownInV = trunc('$$num___');
const roundDown = (v0: any, v1: any) => {
	const roundDownInV = {
		$let: {
			vars: { m___: pow(10, abs('$$dp___')) },
			in: cond(
				gte('$$dp___', 0),
				divide(
					{
						$let: {
							vars: { num___: multiply('$$num___', '$$m___') },
							in: _roundIntDownInV,
						},
					},
					'$$m___'
				),
				multiply(
					{
						$let: {
							vars: { num___: divide('$$num___', '$$m___') },
							in: _roundIntDownInV,
						},
					},
					'$$m___'
				)
			),
		},
	};
	if (v1 !== undefined)
		return {
			$let: { vars: { num___: v0, dp___: trunc(v1) }, in: roundDownInV },
		};
	return trunc(v0);
};
const mroundDown = (v0: any, v1: any) => {
	return {
		$let: {
			vars: { num___: v0, m___: v1 },
			in: cond(
				gt('$$m___', 0),
				multiply(
					{
						$let: {
							vars: { num___: divide('$$num___', '$$m___') },
							in: _roundIntDownInV,
						},
					},
					'$$m___'
				),
				null
			),
		},
	};
};

const arithmeticOps: FuncDict = {
	ABS: {
		val: (e: Expr) => abs(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	ADD: {
		val: (...es: Expr[]) => add(...es.map((e) => e.val)),
		typ: arg0Typ,
		argsHook: typVHookNNNs_DN,
		callHook: lenVHookNNNs_DN,
	},
	SUBTRACT: {
		val: (e0: Expr, e1: Expr) => subtract(e0.val, e1.val),
		typ: subtractTyp,
		argsHook: typVHookNN_DN_DD,
		callHook: lenVHookNN_DN_DD,
	},
	MULTIPLY: {
		val: (...es: Expr[]) => multiply(...es.map((e) => e.val)),
		typ: numberTyp,
		argsHook: typVHookNNNs,
		callHook: lenVHookNNNs,
	},
	DIVIDE: {
		val: (e0: Expr, e1: Expr) => divide(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookNN,
		callHook: lenVHookNN,
	},
	SAFEDIVIDE: {
		val: (e0: Expr, e1: Expr) => safeDivide(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookNN,
		callHook: lenVHookNN,
	},
	NEG: {
		val: (e: Expr) => neg(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	POW: {
		val: (e0: Expr, e1: Expr) => pow(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookNN,
		callHook: typVHookNN,
	},
	CEIL: {
		val: (e: Expr) => ceil(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	FLOOR: {
		val: (e: Expr) => floor(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	LOG: {
		val: (e0: Expr, e1: Expr) => log(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookNN,
		callHook: lenVHookNN,
	},
	LOG10: {
		val: (e: Expr) => log10(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	LN: {
		val: (e: Expr) => ln(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	EXP: {
		val: (e: Expr) => exp(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	MOD: {
		val: (e0: Expr, e1: Expr) => mod(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookNN,
		callHook: lenVHookNN,
	},
	SQRT: {
		val: (e: Expr) => sqrt(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	TRUNC: {
		val: (e: Expr) => trunc(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	SIGN: {
		val: (e: Expr) => sign(e.val),
		typ: numberTyp,
		argsHook: typVHookN,
		callHook: lenVHookN,
	},
	QUOTIENT: {
		val: (e0: Expr, e1: Expr) => quotient(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookNN,
		callHook: lenVHookNN,
	},
	MROUND: {
		val: (e0: Expr, e1: Expr) => mround(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookNN,
		callHook: lenVHookNN,
	},
	ROUND: {
		val: (e0: Expr, e1?: Expr) => round(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookNNo,
		callHook: lenVHookNNo,
	},
	MROUNDUP: {
		val: (e0: Expr, e1: Expr) => mroundUp(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookNN,
		callHook: lenVHookNN,
	},
	ROUNDUP: {
		val: (e0: Expr, e1?: Expr) => roundUp(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookNNo,
		callHook: lenVHookNNo,
	},
	MROUNDDOWN: {
		val: (e0: Expr, e1: Expr) => mroundDown(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookNN,
		callHook: lenVHookNN,
	},
	ROUNDDOWN: {
		val: (e0: Expr, e1?: Expr) =>
			roundDown(e0.val, e1 ? e1.val : undefined),
		typ: numberTyp,
		argsHook: typVHookNNo,
		callHook: lenVHookNNo,
	},
};

export default arithmeticOps;
export {
	abs,
	add,
	subtract,
	multiply,
	divide,
	safeDivide,
	neg,
	pow,
	ceil,
	floor,
	log,
	log10,
	ln,
	exp,
	mod,
	sqrt,
	trunc,
	sign,
	quotient,
	round,
	mround,
	roundDown,
	mroundDown,
	roundUp,
	mroundUp,
};
