import {
	booleanTyp,
	arg0Typ,
	typVHookA,
	lenVHookA,
	typVHookAAAs,
	lenVHookAAAs,
	typVHookAA,
	lenVHookAA,
} from './utils';
import { not } from './booleanOps';
import { FuncDict, Expr, Typ } from 'src/types';
import { intersectTyp } from '../utils';

// OpsVal
const allElementsTrue = (v: any) => ({ $allElementsTrue: v });
const anyElementTrue = (v: any) => ({ $anyElementTrue: v });
const setDifference = (v0: any, v1: any) => ({ $setDifference: [v0, v1] });
const setEquals = (v0: any, v1: any) => ({ $setEquals: [v0, v1] });
const setNotEquals = (v0: any, v1: any) => not(setEquals(v0, v1));
const setIsSubset = (v0: any, v1: any) => ({ $setIsSubset: [v0, v1] });
const setIsSuperset = (v0: any, v1: any) => ({ $setIsSubset: [v1, v0] });
const setIntersection = (...vs: any[]) => {
	const _vs: any[] = [];
	vs.forEach((v) => {
		if (v !== null && v.hasOwnProperty('$setIntersection'))
			_vs.push(...v.$setIntersection);
		else _vs.push(v);
	});
	return { $setIntersection: [..._vs] };
};
const setUnion = (...vs: any[]) => {
	const _vs: any[] = [];
	vs.forEach((v) => {
		if (v !== null && v.hasOwnProperty('$setUnion'))
			_vs.push(...v.$setUnion);
		else _vs.push(v);
	});
	return { $setUnion: [..._vs] };
};

const combinedArrayTyp = (...es: Expr[]) =>
	es.reduce((t: Typ, e: Expr) => intersectTyp(t, e.typ), {
		type: 'array',
	});

const setOps: FuncDict = {
	ALLELEMENTSTRUE: {
		val: (e: Expr) => allElementsTrue(e.val),
		typ: booleanTyp,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
	ANYELEMENTTRUE: {
		val: (e: Expr) => anyElementTrue(e.val),
		typ: booleanTyp,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
	SETDIFFERENCE: {
		val: (e0: Expr, e1: Expr) => setDifference(e0.val, e1.val),
		typ: arg0Typ,
		argsHook: typVHookAA,
		callHook: lenVHookAA,
	},
	SETEQUALS: {
		val: (e0: Expr, e1: Expr) => setEquals(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAA,
		callHook: lenVHookAA,
	},
	SETNOTEQUALS: {
		val: (e0: Expr, e1: Expr) => setNotEquals(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAA,
		callHook: lenVHookAA,
	},
	SETISSUBSET: {
		val: (e0: Expr, e1: Expr) => setIsSubset(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAA,
		callHook: lenVHookAA,
	},
	SETISSUPERSET: {
		val: (e0: Expr, e1: Expr) => setIsSuperset(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAA,
		callHook: lenVHookAA,
	},
	SETINTERSECTION: {
		val: (...es: Expr[]) => setIntersection(...es.map((e) => e.val)),
		typ: combinedArrayTyp,
		argsHook: typVHookAAAs,
		callHook: lenVHookAAAs,
	},
	SETUNION: {
		val: (...es: Expr[]) => setUnion(...es.map((e) => e.val)),
		typ: combinedArrayTyp,
		argsHook: typVHookAAAs,
		callHook: lenVHookAAAs,
	},
};
// alias
// setOps['ALL'] = setOps['ALLELEMENTSTRUE'];
// setOps['ANY'] = setOps['ANYELEMENTSTRUE'];
// setOps['COMPLEMENT'] = setOps['SETDIFFERENCE'];
// setOps['UNION'] = setOps['SETUNION'];
// setOps['INTERSECTION'] = setOps['SETINTERSECTION'];
// setOps['SETEQ'] = setOps['SETEQUALS'];
// setOps['SETNE'] = setOps['SETNOTEQUALS'];
// setOps['SETLTE'] = setOps['SETISSUBSET'];
// setOps['SETGTE'] = setOps['SETISSUPERSET'];

export default setOps;
export {
	allElementsTrue,
	anyElementTrue as anyElementsTrue,
	setDifference,
	setEquals,
	setNotEquals,
	setIsSubset,
	setIsSuperset,
	setIntersection,
	setUnion,
};
