import {
	numberTyp,
	stringTyp,
	stringArrayTyp,
	typVHookSSSs,
	lenVHookSSSs,
	typVHookSSo,
	lenVHookSSo,
	typVHookSS,
	lenVHookSS,
	typVHookS,
	lenVHookS,
	typVHookSSNoNo,
	lenVHookSSNoNo,
	typVHookSNN,
	lenVHookSNN,
	typVHookSN,
	lenVHookSN,
	typVHookSNNo,
	lenVHookSNNo,
} from './utils';
import { min, max } from './accumulatorOps';
import { add, subtract, trunc } from './arithmeticOps';
import { and } from './booleanOps';
import { gt, lt, gte } from './comparisonOps';
import { cond } from './conditionalOps';
import { FuncDict, Expr } from 'src/types';

// OpsVal
const concat = (...vs: any[]) => {
	const _vs: any[] = [];
	vs.forEach((v) => {
		if (v !== null && v.hasOwnProperty('$concat')) _vs.push(...v.$concat);
		else _vs.push(v);
	});
	return { $concat: [..._vs] };
};
const indexOfBytes = (v0: any, v1: any, v2?: any, v3?: any) => ({
	$indexOfBytes: [v0, v1, v2, v3].filter((v) => v !== undefined),
});
const indexOfCP = (v0: any, v1: any, v2?: any, v3?: any) => ({
	$indexOfCP: [v0, v1, v2, v3].filter((v) => v !== undefined),
});
const ltrim = (v0: any, v1?: any) => ({
	$ltrim: v1 !== undefined ? [v0, v1] : v0,
});
const rtrim = (v0: any, v1?: any) => ({
	$rtrim: v1 !== undefined ? [v0, v1] : v0,
});
const trim = (v0: any, v1?: any) => ({
	$trim: v1 !== undefined ? [v0, v1] : v0,
});
const split = (v0: any, v1: any) => ({ $split: [v0, v1] });
const strLenBytes = (v: any) => ({ $strLenBytes: v });
const strLenCP = (v: any) => ({ $strLenCP: v });
const strcasecmp = (v0: any, v1: any) => ({ $strcasecmp: [v0, v1] });
const substrBytes = (v0: any, v1: any, v2: any) => ({
	$substrBytes: [v0, v1, v2],
});
const substrCP = (v0: any, v1: any, v2: any) => ({ $substrCP: [v0, v1, v2] });
const toLower = (v: any) => ({ $toLower: v });
const toUpper = (v: any) => ({ $toUpper: v });
const strElemAt = (v0: any, v1: any) => {
	const strElemAtInV = {
		$let: {
			vars: { len___: strLenCP('$$str___') },
			in: {
				$let: {
					vars: {
						absPos___: cond(
							gte('$$pos___', 0),
							'$$pos___',
							add('$$len___', '$$pos___')
						),
					},
					in: cond(
						and(
							gte('$$absPos___', 0),
							lt('$$absPos___', '$$len___')
						),
						substrCP('$$str___', '$$absPos___', 1),
						''
					),
				},
			},
		},
	};
	return {
		$let: { vars: { str___: v0, pos___: trunc(v1) }, in: strElemAtInV },
	};
};
const advanceStrSlice = (v0: any, v1: any, v2: any) => {
	const sliceInV = {
		$let: {
			vars: {
				absPos1___: cond(
					gte('$$pos1___', 0),
					min(['$$pos1___', '$$len___']),
					max([{ $add: ['$$len___', '$$pos1___'] }, 0])
				),
				absPos2___: cond(
					gte('$$pos2___', 0),
					min(['$$pos2___', '$$len___']),
					max([{ $add: ['$$len___', '$$pos2___'] }, 0])
				),
			},
			in: cond(
				gt('$$absPos2___', '$$absPos1___'),
				substrCP(
					'$$str___',
					'$$absPos1___',
					subtract('$$absPos2___', '$$absPos1___')
				),
				''
			),
		},
	};
	return {
		$let: {
			vars: { str___: v0 },
			in: {
				$let: {
					vars: { len___: strLenCP('$$str___') },
					in: {
						$let: {
							vars: {
								pos1___: trunc(v1),
								pos2___:
									v2 !== undefined ? trunc(v2) : '$$len___',
							},
							in: sliceInV,
						},
					},
				},
			},
		},
	};
};

const stringOps: FuncDict = {
	CONCAT: {
		val: (...es: Expr[]) => concat(...es.map((e) => e.val)),
		typ: stringTyp,
		argsHook: typVHookSSSs,
		callHook: lenVHookSSSs,
	},
	INDEXOFBYTES: {
		val: (e0: Expr, e1: Expr, e2?: Expr, e3?: Expr) =>
			indexOfBytes(
				e0.val,
				e1.val,
				e2 ? e2.val : undefined,
				e3 ? e3.val : undefined
			),
		typ: numberTyp,
		argsHook: typVHookSSNoNo,
		callHook: lenVHookSSNoNo,
	},
	INDEXOFCP: {
		val: (e0: Expr, e1: Expr, e2?: Expr, e3?: Expr) =>
			indexOfCP(
				e0.val,
				e1.val,
				e2 ? e2.val : undefined,
				e3 ? e3.val : undefined
			),
		typ: numberTyp,
		argsHook: typVHookSSNoNo,
		callHook: lenVHookSSNoNo,
	},
	LTRIM: {
		val: (e0: Expr, e1?: Expr) => ltrim(e0.val, e1 ? e1.val : undefined),
		typ: stringTyp,
		argsHook: typVHookSSo,
		callHook: lenVHookSSo,
	},
	RTRIM: {
		val: (e0: Expr, e1?: Expr) => rtrim(e0.val, e1 ? e1.val : undefined),
		typ: stringTyp,
		argsHook: typVHookSSo,
		callHook: lenVHookSSo,
	},
	TRIM: {
		val: (e0: Expr, e1?: Expr) => trim(e0.val, e1 ? e1.val : undefined),
		typ: stringTyp,
		argsHook: typVHookSSo,
		callHook: lenVHookSSo,
	},
	SPLIT: {
		val: (e0: Expr, e1: Expr) => split(e0.val, e1.val),
		typ: stringArrayTyp,
		argsHook: typVHookSS,
		callHook: lenVHookSS,
	},
	STRLENBYTES: {
		val: (e: Expr) => strLenBytes(e.val),
		typ: numberTyp,
		argsHook: typVHookS,
		callHook: lenVHookS,
	},
	STRLENCP: {
		val: (e: Expr) => strLenCP(e.val),
		typ: numberTyp,
		argsHook: typVHookS,
		callHook: lenVHookS,
	},
	STRCASECMP: {
		val: (e0: Expr, e1: Expr) => strcasecmp(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookSS,
		callHook: lenVHookSS,
	},
	SUBSTRBYTES: {
		val: (e0: Expr, e1: Expr, e2: Expr) =>
			substrBytes(e0.val, e1.val, e2.val),
		typ: stringTyp,
		argsHook: typVHookSNN,
		callHook: lenVHookSNN,
	},
	SUBSTRCP: {
		val: (e0: Expr, e1: Expr, e2: Expr) => substrCP(e0.val, e1.val, e2.val),
		typ: stringTyp,
		argsHook: typVHookSNN,
		callHook: lenVHookSNN,
	},
	TOLOWER: {
		val: (e: Expr) => toLower(e.val),
		typ: stringTyp,
		argsHook: typVHookS,
		callHook: lenVHookS,
	},
	TOUPPER: {
		val: (e: Expr) => toUpper(e.val),
		typ: stringTyp,
		argsHook: typVHookS,
		callHook: lenVHookS,
	},
	STRELEMAT: {
		val: (e0: Expr, e1: Expr) => strElemAt(e0.val, e1.val),
		typ: stringTyp,
		argsHook: typVHookSN,
		callHook: lenVHookSN,
	},
	ADVANCESTRSLICE: {
		val: (e0: Expr, e1: Expr, e2?: Expr) =>
			advanceStrSlice(e0.val, e1.val, e2 ? e2.val : undefined),
		typ: stringTyp,
		argsHook: typVHookSNNo,
		callHook: lenVHookSNNo,
	},
};
//alias
// stringOps['CONCATSTRS'] = stringOps['CONCAT'];
// stringOps['INDEXOFSTR'] = stringOps['INDEXOFCP'];
// stringOps['SUBSTR'] = stringOps['SUBSTRCP'];
// stringOps['STRLEN'] = stringOps['STRLENCP'];

export default stringOps;
export {
	concat,
	indexOfBytes,
	indexOfCP,
	ltrim,
	rtrim,
	trim,
	split,
	strLenBytes,
	strLenCP,
	strcasecmp,
	substrBytes,
	substrCP,
	toLower,
	toUpper,
	strElemAt,
	advanceStrSlice,
};
