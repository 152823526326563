import Lexer from './Lexer';
import Debug from 'debug';
const formulaLexer = new Lexer();

const debug = Debug('formula-to-mongodb-exp:formulaLexer');

/* skip whitespaces */
formulaLexer.addRule(/\s+|\t+|\n+/, function() {});

/* NUMBER (Support decimal fractions, exponential notation, percentage.) */
formulaLexer.addRule(/[0-9]+(?:\.[0-9]+)?(?:e[-+]?[0-9]+)?%?/, function(
	lexeme: string
) {
	const val = lexeme.endsWith('%')
		? Number(lexeme.slice(0, -1)) / 100
		: Number(lexeme);
	debug(`${lexeme} -> NUMBER`);
	return { token: 'NUMBER', val };
});

/* STRING (Support single quote, double quote.) */
formulaLexer.addRule(/(?:".*?")|(?:'.*?')/, function(lexeme: string) {
	const val = lexeme.slice(1, -1);
	debug(`${lexeme} -> STRING`);
	return { token: 'STRING', val };
});

/* BOOLEAN (true, false are case sensitive.)*/
formulaLexer.addRule(/true|false/, function(lexeme: string) {
	const val = lexeme === 'true';
	debug(`${lexeme} -> BOOLEAN`);
	return { token: 'BOOLEAN', val };
});

/* NULL */
const NULLTokenVal = { token: 'NULL', val: null };
formulaLexer.addRule(/null/, function(lexeme: string) {
	debug(`${lexeme} -> NULL`);
	return NULLTokenVal;
});

/* TYP */
formulaLexer.addRule(
	/string|number|boolean|date|array|object|undetermined|any/,
	function(lexeme: string) {
		debug(`${lexeme} -> TYP`);
		return { token: 'TYP', val: lexeme };
	}
);

/* OPERATORS */
formulaLexer.addRule(
	/[+\-*/^()}[\],%;:]|[!<>=?]=?|let|in|as|&&|\|\|?|\.(?:\.\.)?|{(?:(?:&[&!]|\|\||[!<>=]=)})?/,
	function(lexeme: string) {
		debug(`${lexeme} -> ${lexeme}`);
		return { token: lexeme, val: undefined };
	}
);

/* KEY */
formulaLexer.addRule(/\w+/, function(lexeme: string) {
	const val = lexeme;
	debug(`${lexeme} -> KEY`);
	return { token: 'KEY', val };
});

/* EOF */
const EOFToken = { token: 'EOF', val: undefined };
formulaLexer.addRule(/$/, function() {
	debug('-> EOF');
	return EOFToken;
});

/* UNKNOWN */
// const UNKNOWNToken = { token: 'UNKNOWN', val: undefined };
// formulaLexer.addRule(/./, function(lexeme: string) {
// 	debug(`${lexeme} -> UNKNOWN`);
// 	return UNKNOWNToken;
// });

export default formulaLexer;
