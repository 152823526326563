import {
	createValidationHook,
	arg0Typ,
	typVHookAny,
	numberTyp,
	sigNN,
	sigSS,
	sigDN,
	sigSN,
	sigAN,
	sigSNNo,
	sigANNo,
	sigN,
	sigS,
	sigA,
	createForbiddenAnyHook,
} from './utils';
import { Expr, Typ, FuncDict, FuncScope } from 'src/types';
import { add, abs } from './arithmeticOps';
import { concat, strElemAt, advanceStrSlice, strLenCP } from './stringOps';
import { arrayElemAt, advanceArraySlice, size } from './arrayOps';
import { isCompatibleTyp } from '../utils';

const {
	typValidationHook: typVHookNN_SS_DN,
	lenValidationHook: lenVHookNN_SS_DN,
} = createValidationHook([sigNN, sigSS, sigDN]);
const {
	typValidationHook: typVHookSN_AN,
	lenValidationHook: lenVHookSN_AN,
} = createValidationHook([sigSN, sigAN]);
const {
	typValidationHook: typVHookSNNo_ANNo,
	lenValidationHook: lenVHookSNNo_ANNo,
} = createValidationHook([sigSNNo, sigANNo]);
const {
	typValidationHook: typVHookN_S_A,
	lenValidationHook: lenVHookN_S_A,
} = createValidationHook([sigN, sigS, sigA]);
const forbiddenAnyHook01 = createForbiddenAnyHook([0, 1]);
const forbiddenAnyHook0 = createForbiddenAnyHook([0]);

const literal = (v: any) => ({ $literal: v });
const _literalTyp = (e: Expr): Typ => {
	const v = e.val;
	if (typeof v === 'string') return { type: 'string' };
	if (typeof v === 'number') return { type: 'number' };
	if (typeof v === 'boolean') return { type: 'boolean' };
	if (v instanceof Date) return { type: 'date' };
	if (v instanceof Array) {
		const items = v.reduce(
			(_t, _v) => {
				const t = _literalTyp({
					typ: { type: 'any' },
					val: _v,
				});
				return isCompatibleTyp(_t, t) ? t : { type: 'undetermined' };
			},
			{ type: 'any' }
		);
		return { type: 'array', items };
	}
	if (typeof v === 'object') return { type: 'object' };
	return { type: 'undetermined' };
};
const _plusVal = (e0: Expr, e1: Expr) => {
	const { typ: t0, val: v0 } = e0;
	const { typ: t1, val: v1 } = e1;
	let valOp: (v0: any, v1: any) => any = () => undefined;
	if ((t0.type === 'date' || t0.type === 'number') && t1.type === 'number')
		valOp = add;
	else if (t0.type === 'string' && t1.type === 'string') valOp = concat;
	return valOp(v0, v1);
};
const _plusTyp = (e0: Expr, e1: Expr): Typ => {
	const { typ: t0 } = e0;
	const { typ: t1 } = e1;
	let typ: Typ = { type: 'undetermined' };
	if (t0.type === 'number' && t1.type === 'number') typ = { type: 'number' };
	else if (t0.type === 'date' && t1.type === 'number') typ = { type: 'date' };
	else if (t0.type === 'string' && t1.type === 'string')
		typ = { type: 'string' };
	return typ;
};
const _indexVal = (e0: Expr, e1: Expr) => {
	const { typ: t0, val: v0 } = e0;
	const { val: v1 } = e1;
	let valOp: (v0: any, v1: any) => any = () => undefined;
	if (t0.type === 'array') valOp = arrayElemAt;
	else if (t0.type === 'string') valOp = strElemAt;
	return valOp(v0, v1);
};
const _indexTyp = (e0: Expr): Typ => {
	const { typ: t0 } = e0;
	let typ: Typ = { type: 'undetermined' };
	if (t0.type === 'array' && t0.items) typ = t0.items;
	else if (t0.type === 'string') typ = { type: 'string' };
	return typ;
};
const _sliceVal = (e0: Expr, e1: Expr, e2?: Expr) => {
	const { typ: t0, val: v0 } = e0;
	const { val: v1 } = e1;
	const v2 = e2 ? e2.val : undefined;
	let valOp: (v0: any, v1: any, v2?: any) => any = () => undefined;
	if (t0.type === 'array') valOp = advanceArraySlice;
	else if (t0.type === 'string') valOp = advanceStrSlice;
	return valOp(v0, v1, v2);
};
const _lengthVal = (e0: Expr) => {
	const { typ: t0, val: v0 } = e0;
	let valOp: (v0: any) => any = () => undefined;
	if (t0.type === 'array') valOp = size;
	else if (t0.type === 'string') valOp = strLenCP;
	else if (t0.type === 'number') valOp = abs;
	return valOp(v0);
};

const miscOps: FuncDict = {
	LITERAL: {
		// Ref: https://docs.mongodb.com/manual/reference/operator/aggregation/literal/
		val: (e: Expr) => literal(e.val),
		typ: _literalTyp,
		argsHook: typVHookAny,
		callHook: typVHookAny,
	},
	'+': {
		val: _plusVal,
		typ: _plusTyp,
		argsHook: (funcScope: FuncScope) => {
			typVHookNN_SS_DN(funcScope);
			forbiddenAnyHook01(funcScope);
		},
		callHook: lenVHookNN_SS_DN,
	},
	'[ ]': {
		val: _indexVal,
		typ: _indexTyp,
		argsHook: (funcScope: FuncScope) => {
			typVHookSN_AN(funcScope);
			forbiddenAnyHook0(funcScope);
		},
		callHook: lenVHookSN_AN,
	},
	'[:]': {
		val: _sliceVal,
		typ: arg0Typ,
		argsHook: (funcScope: FuncScope) => {
			typVHookSNNo_ANNo(funcScope);
			forbiddenAnyHook0(funcScope);
		},
		callHook: lenVHookSNNo_ANNo,
	},
	'| |': {
		val: _lengthVal,
		typ: numberTyp,
		argsHook: (funcScope: FuncScope) => {
			typVHookN_S_A;
			forbiddenAnyHook0(funcScope);
		},
		callHook: lenVHookN_S_A,
	},
};

export default miscOps;
export { literal };
