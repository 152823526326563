import { numberTyp, arg0ItemTyp, typVHookA, lenVHookA } from './utils';
import { Expr, FuncDict } from 'src/types';

// OpsVal
const avg = (v: any) => ({ $avg: v });
const sum = (v: any) => ({ $sum: v });
const max = (v: any) => ({ $max: v });
const min = (v: any) => ({ $min: v });
const stdDevPop = (v: any) => ({ $stdDevPop: v });
const stdDevSamp = (v: any) => ({ $stdDevSamp: v });

const accumulatorOps: FuncDict = {
	AVG: {
		val: (e: Expr) => avg(e.val),
		typ: numberTyp,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
	SUM: {
		val: (e: Expr) => sum(e.val),
		typ: numberTyp,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
	MAX: {
		val: (e: Expr) => max(e.val),
		typ: arg0ItemTyp,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
	MIN: {
		val: (e: Expr) => min(e.val),
		typ: arg0ItemTyp,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
	STDDEVPOP: {
		val: (e: Expr) => stdDevPop(e.val),
		typ: numberTyp,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
	STDDEVSAMP: {
		val: (e: Expr) => stdDevSamp(e.val),
		typ: numberTyp,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
};
// alias
// accumulatorOps['AVERAGE'] = accumulatorOps['AVG'];
// accumulatorOps['STDDEV'] = accumulatorOps['STDDEVSAMP'];
// accumulatorOps['STDDEVS'] = accumulatorOps['STDDEVSAMP'];
// accumulatorOps['STDDEVP'] = accumulatorOps['STDDEVPOP'];

export default accumulatorOps;
export { avg, sum, max, min, stdDevPop, stdDevSamp };
