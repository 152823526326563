import { Expr, Typ, FuncScope, FuncSignature } from 'src/types';
import {
	maxWithIndices,
	isSuperTyp,
	ExprTypError,
	ExprsLenError,
} from '../utils';

export function booleanTyp(): Typ {
	return { type: 'boolean' };
}
export function numberTyp(): Typ {
	return { type: 'number' };
}
export function stringTyp(): Typ {
	return { type: 'string' };
}
export function dateTyp(): Typ {
	return { type: 'date' };
}
export function numberArrayTyp(): Typ {
	return { type: 'array', items: { type: 'number' } };
}
export function stringArrayTyp(): Typ {
	return { type: 'array', items: { type: 'string' } };
}
export function arg0Typ(e0: Expr): Typ {
	return e0.typ;
}
export function arg2Typ(e0: Expr, e1: Expr, e2: Expr): Typ {
	return e2.typ;
}
export function arg0ItemTyp(e0: Expr): Typ {
	const t0 = e0.typ;
	if ('items' in t0) {
		const iT0 = t0.items;
		if (iT0) return iT0;
	}
	return { type: 'undetermined' };
}

export function createValidationHook(
	signature: FuncSignature | FuncSignature[],
	compareTyp: (
		receivedArgTyp: Typ,
		expectedArgTyp: Typ
	) => boolean = isSuperTyp
) {
	function countValidArgs(signature: FuncSignature, args: Expr[]): number {
		let validArgsCount = 0;
		const {
			argsTyp = [{ type: 'any' }] as Typ[],
			spreadArgTyp = { type: 'any' } as Typ,
		} = signature;
		const argsCount = args.length;
		for (let i = 0; i < argsCount; ++i) {
			const argTyp = args[i].typ;
			const expectedArgTyp: Typ =
				i < argsTyp.length ? argsTyp[i] : spreadArgTyp;
			if (compareTyp(argTyp, expectedArgTyp)) ++validArgsCount;
			else break;
		}
		return validArgsCount;
	}
	const sigatures = signature instanceof Array ? signature : [signature];

	function typValidationHook(funcScope: FuncScope): void {
		const { name, args, argsLoc } = funcScope;
		const argsCount = args.length;
		const validArgsCounts = sigatures.map((signature) =>
			countValidArgs(signature, args)
		);
		const {
			max: validArgsCount,
			indices: validSignatureIndices,
		} = maxWithIndices(validArgsCounts);
		let expectedArgTyps: Typ[] = [];
		for (let i = 0, l = validSignatureIndices.length; i < l; ++i) {
			const validIndex = validSignatureIndices[i];
			const validSignature = sigatures[validIndex];
			const {
				argsTyp = [{ type: 'any' }] as Typ[],
				spreadArgTyp = { type: 'any' } as Typ,
			} = validSignature;
			const expectedArgTyp: Typ =
				validArgsCount < argsTyp.length
					? argsTyp[validArgsCount]
					: spreadArgTyp;
			expectedArgTyps.push(expectedArgTyp);
		}
		if (validArgsCount < argsCount) {
			throw new ExprTypError({
				scopeName: name,
				index: validArgsCount,
				receivedTyp: args[validArgsCount].typ,
				expectedTyps: expectedArgTyps,
				loc: argsLoc[validArgsCount],
			});
		}
	}

	function lenValidationHook(funcScope: FuncScope): void {
		const { name, args, argsLoc } = funcScope;
		const argsCount = args.length;
		const validArgsCounts = sigatures.map((signature) =>
			countValidArgs(signature, args)
		);
		const { indices: validSignatureIndices } = maxWithIndices(
			validArgsCounts
		);
		let _minArgsCount: number = Infinity;
		let _maxArgsCount: number = 0;
		for (let i = 0, l = validSignatureIndices.length; i < l; ++i) {
			const validIndex = validSignatureIndices[i];
			const validSignature = sigatures[validIndex];
			const {
				minArgsCount = 0,
				maxArgsCount = Infinity,
			} = validSignature;
			if (_minArgsCount > minArgsCount) _minArgsCount = minArgsCount;
			if (_maxArgsCount < maxArgsCount) _maxArgsCount = maxArgsCount;
		}

		if (argsCount < _minArgsCount || argsCount > _maxArgsCount) {
			const firstArgsLoc = argsLoc[0];
			const lastArgsLoc = argsLoc[argsLoc.length - 1];
			throw new ExprsLenError({
				scopeName: name,
				receivedLen: argsCount,
				expectedMinLen: _minArgsCount,
				expectedMaxLen: _maxArgsCount,
				loc:
					firstArgsLoc && lastArgsLoc
						? {
								first_line: firstArgsLoc.first_line,
								last_line: lastArgsLoc.last_line,
								first_column: firstArgsLoc.first_column,
								last_column: lastArgsLoc.last_column,
						  }
						: null,
			});
		}
	}

	return { typValidationHook, lenValidationHook };
}

export function createRequiredPropertyHook(
	argIndex: number,
	property: string | string[]
) {
	const properties = property instanceof Array ? property : [property];
	return function(funcScope: FuncScope) {
		const { name, args, argsLoc } = funcScope;
		if (args.length > argIndex) {
			const arg = args[argIndex];
			const t = arg.typ;
			let tProperties = {};
			if ('properties' in t && t.properties) tProperties = t.properties;
			for (let i = 0, l = properties.length; i < l; ++i) {
				const property = properties[i];
				if (!tProperties.hasOwnProperty(property))
					throw new ExprTypError({
						scopeName: name,
						index: argIndex,
						loc: argsLoc[argIndex],
						reason: `Required property "${property}".`,
					});
			}
		}
	};
}

export function createForbiddenAnyHook(argIndices: number[]) {
	return function(funcScope: FuncScope) {
		const { name, args, argsLoc } = funcScope;
		for (let i = 0, l = args.length; i < l; ++i) {
			if (argIndices.indexOf(i) >= 0) {
				const typ = args[i].typ;
				if (typ.type === 'any') {
					throw new ExprTypError({
						scopeName: name,
						index: i,
						receivedTyp: typ,
						loc: argsLoc[i],
						reason:
							'Expr in overloaded operator must not have typ {"type":"any"}.',
					});
				}
			}
		}
	};
}

export const sigN: FuncSignature = {
	argsTyp: [{ type: 'number' }],
	maxArgsCount: 1,
	minArgsCount: 1,
};
export const sigNN: FuncSignature = {
	argsTyp: [{ type: 'number' }, { type: 'number' }],
	maxArgsCount: 2,
	minArgsCount: 2,
};
export const sigNNo: FuncSignature = {
	argsTyp: [{ type: 'number' }, { type: 'number' }],
	maxArgsCount: 2,
	minArgsCount: 1,
};
export const sigNNNo: FuncSignature = {
	argsTyp: [{ type: 'number' }, { type: 'number' }, { type: 'number' }],
	maxArgsCount: 3,
	minArgsCount: 2,
};
export const sigNNNs: FuncSignature = {
	argsTyp: [{ type: 'number' }, { type: 'number' }],
	spreadArgTyp: { type: 'number' },
	minArgsCount: 2,
};
export const sigDN: FuncSignature = {
	argsTyp: [{ type: 'date' }, { type: 'number' }],
	maxArgsCount: 2,
	minArgsCount: 2,
};
export const sigDD: FuncSignature = {
	argsTyp: [{ type: 'date' }, { type: 'date' }],
	maxArgsCount: 2,
	minArgsCount: 2,
};
export const sigA: FuncSignature = {
	argsTyp: [{ type: 'array' }],
	maxArgsCount: 1,
	minArgsCount: 1,
};
export const sigAA: FuncSignature = {
	argsTyp: [{ type: 'array' }, { type: 'array' }],
	maxArgsCount: 2,
	minArgsCount: 2,
};
export const sigAAAs: FuncSignature = {
	argsTyp: [{ type: 'array' }, { type: 'array' }],
	spreadArgTyp: { type: 'array' },
	minArgsCount: 2,
};
export const sigASAny: FuncSignature = {
	argsTyp: [{ type: 'array' }, { type: 'string' }, { type: 'any' }],
	maxArgsCount: 3,
	minArgsCount: 3,
};
export const sigASB: FuncSignature = {
	argsTyp: [{ type: 'array' }, { type: 'string' }, { type: 'boolean' }],
	maxArgsCount: 3,
	minArgsCount: 3,
};
export const sigAAnyNoNo: FuncSignature = {
	argsTyp: [
		{ type: 'array' },
		{ type: 'any' },
		{ type: 'number' },
		{ type: 'number' },
	],
	maxArgsCount: 4,
	minArgsCount: 2,
};
export const sigAN: FuncSignature = {
	argsTyp: [{ type: 'array' }, { type: 'number' }],
	maxArgsCount: 2,
	minArgsCount: 2,
};
export const sigANNo: FuncSignature = {
	argsTyp: [{ type: 'array' }, { type: 'number' }, { type: 'number' }],
	maxArgsCount: 3,
	minArgsCount: 2,
};
export const sigAny: FuncSignature = {
	argsTyp: [{ type: 'any' }],
	maxArgsCount: 1,
	minArgsCount: 1,
};
export const sigAnyAny: FuncSignature = {
	argsTyp: [{ type: 'any' }, { type: 'any' }],
	minArgsCount: 2,
	maxArgsCount: 2,
};
export const sigAnyA: FuncSignature = {
	argsTyp: [{ type: 'any' }, { type: 'array' }],
	maxArgsCount: 2,
	minArgsCount: 2,
};
export const sigAnyAnyAny: FuncSignature = {
	argsTyp: [{ type: 'any' }, { type: 'any' }, { type: 'any' }],
	maxArgsCount: 3,
	minArgsCount: 3,
};
export const sigAnyAnyAnys: FuncSignature = {
	argsTyp: [{ type: 'any' }, { type: 'any' }],
	spreadArgTyp: { type: 'any' },
	minArgsCount: 2,
};
export const sigS: FuncSignature = {
	argsTyp: [{ type: 'string' }],
	maxArgsCount: 1,
	minArgsCount: 1,
};
export const sigSSo: FuncSignature = {
	argsTyp: [{ type: 'string' }, { type: 'string' }],
	maxArgsCount: 2,
	minArgsCount: 1,
};
export const sigSSoSo: FuncSignature = {
	argsTyp: [{ type: 'string' }, { type: 'string' }, { type: 'string' }],
	maxArgsCount: 3,
	minArgsCount: 1,
};
export const sigSS: FuncSignature = {
	argsTyp: [{ type: 'string' }, { type: 'string' }],
	maxArgsCount: 2,
	minArgsCount: 2,
};
export const sigSSSs: FuncSignature = {
	argsTyp: [{ type: 'string' }, { type: 'string' }],
	spreadArgTyp: { type: 'string' },
	minArgsCount: 2,
};
export const sigSSNoNo: FuncSignature = {
	argsTyp: [
		{ type: 'string' },
		{ type: 'string' },
		{ type: 'number' },
		{ type: 'number' },
	],
	maxArgsCount: 4,
	minArgsCount: 2,
};
export const sigSN: FuncSignature = {
	argsTyp: [{ type: 'string' }, { type: 'number' }],
	maxArgsCount: 2,
	minArgsCount: 2,
};
export const sigSNNo: FuncSignature = {
	argsTyp: [{ type: 'string' }, { type: 'number' }, { type: 'number' }],
	maxArgsCount: 3,
	minArgsCount: 2,
};
export const sigSNN: FuncSignature = {
	argsTyp: [{ type: 'string' }, { type: 'number' }, { type: 'number' }],
	maxArgsCount: 3,
	minArgsCount: 3,
};
export const sigDSo: FuncSignature = {
	argsTyp: [{ type: 'date' }, { type: 'string' }],
	maxArgsCount: 2,
	minArgsCount: 1,
};
export const sigDSoSo: FuncSignature = {
	argsTyp: [{ type: 'date' }, { type: 'string' }, { type: 'string' }],
	maxArgsCount: 3,
	minArgsCount: 1,
};
export const sigOOOs: FuncSignature = {
	argsTyp: [{ type: 'object' }, { type: 'object' }],
	spreadArgTyp: { type: 'object' },
	minArgsCount: 2,
};
export const {
	typValidationHook: typVHookN,
	lenValidationHook: lenVHookN,
} = createValidationHook(sigN);
export const {
	typValidationHook: typVHookNN,
	lenValidationHook: lenVHookNN,
} = createValidationHook(sigNN);
export const {
	typValidationHook: typVHookNNo,
	lenValidationHook: lenVHookNNo,
} = createValidationHook(sigNNo);
export const {
	typValidationHook: typVHookNNNo,
	lenValidationHook: lenVHookNNNo,
} = createValidationHook(sigNNNo);
export const {
	typValidationHook: typVHookNNNs,
	lenValidationHook: lenVHookNNNs,
} = createValidationHook(sigNNNs);
export const {
	typValidationHook: typVHookNNNs_DN,
	lenValidationHook: lenVHookNNNs_DN,
} = createValidationHook([sigNNNs, sigDN]);
export const {
	typValidationHook: typVHookNN_DN_DD,
	lenValidationHook: lenVHookNN_DN_DD,
} = createValidationHook([sigNN, sigDN, sigDD]);
export const {
	typValidationHook: typVHookA,
	lenValidationHook: lenVHookA,
} = createValidationHook(sigA);
export const {
	typValidationHook: typVHookAA,
	lenValidationHook: lenVHookAA,
} = createValidationHook(sigAA);
export const {
	typValidationHook: typVHookAAAs,
	lenValidationHook: lenVHookAAAs,
} = createValidationHook(sigAAAs);
export const {
	typValidationHook: typVHookASAny,
	lenValidationHook: lenVHookASAny,
} = createValidationHook(sigASAny);
export const {
	typValidationHook: typVHookASB,
	lenValidationHook: lenVHookASB,
} = createValidationHook(sigASB);
export const {
	typValidationHook: typVHookAN,
	lenValidationHook: lenVHookAN,
} = createValidationHook(sigAN);
export const {
	typValidationHook: typVHookANNo,
	lenValidationHook: lenVHookANNo,
} = createValidationHook(sigANNo);
export const {
	typValidationHook: typVHookAAnyNoNo,
	lenValidationHook: lenVHookAAnyNoNo,
} = createValidationHook(sigAAnyNoNo);
export const {
	typValidationHook: typVHookAny,
	lenValidationHook: lenVHookAny,
} = createValidationHook(sigAny);
export const {
	typValidationHook: typVHookAnyAny,
	lenValidationHook: lenVHookAnyAny,
} = createValidationHook(sigAnyAny);
export const {
	typValidationHook: typVHookAnyAnyAnys,
	lenValidationHook: lenVHookAnyAnyAnys,
} = createValidationHook(sigAnyAnyAnys);
export const {
	typValidationHook: typVHookAnyA,
	lenValidationHook: lenVHookAnyA,
} = createValidationHook(sigAnyA);
export const {
	typValidationHook: typVHookAnyAnyAny,
	lenValidationHook: lenVHookAnyAnyAny,
} = createValidationHook(sigAnyAnyAny);
export const {
	typValidationHook: typVHookS,
	lenValidationHook: lenVHookS,
} = createValidationHook(sigS);
export const {
	typValidationHook: typVHookSSo,
	lenValidationHook: lenVHookSSo,
} = createValidationHook(sigSSo);
export const {
	typValidationHook: typVHookSSoSo,
	lenValidationHook: lenVHookSSoSo,
} = createValidationHook(sigSSoSo);
export const {
	typValidationHook: typVHookSS,
	lenValidationHook: lenVHookSS,
} = createValidationHook(sigSS);
export const {
	typValidationHook: typVHookSSSs,
	lenValidationHook: lenVHookSSSs,
} = createValidationHook(sigSSSs);
export const {
	typValidationHook: typVHookSSNoNo,
	lenValidationHook: lenVHookSSNoNo,
} = createValidationHook(sigSSNoNo);
export const {
	typValidationHook: typVHookSN,
	lenValidationHook: lenVHookSN,
} = createValidationHook(sigSN);
export const {
	typValidationHook: typVHookSNNo,
	lenValidationHook: lenVHookSNNo,
} = createValidationHook(sigSNNo);
export const {
	typValidationHook: typVHookSNN,
	lenValidationHook: lenVHookSNN,
} = createValidationHook(sigSNN);
export const {
	typValidationHook: typVHookDSo,
	lenValidationHook: lenVHookDSo,
} = createValidationHook(sigDSo);
export const {
	typValidationHook: typVHookDSoSo,
	lenValidationHook: lenVHookDSoSo,
} = createValidationHook(sigDSoSo);
export const {
	typValidationHook: typVHookOOOs,
	lenValidationHook: lenVHookOOOs,
} = createValidationHook(sigOOOs);
