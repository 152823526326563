import { typVHookOOOs, lenVHookOOOs } from './utils';
import { FuncDict, Expr } from 'src/types';

// OpsVal
const mergeObjects = (...vs: any[]) => {
	const _vs: any[] = [];
	vs.forEach((v) => {
		if (v !== null && v.hasOwnProperty('$mergeObjects'))
			_vs.push(...v.$mergeObjects);
		else _vs.push(v);
	});
	return { $mergeObjects: [..._vs] };
};

const objectOps: FuncDict = {
	MERGEOBJECTS: {
		val: (...es: Expr[]) => mergeObjects(...es.map((e) => e.val)),
		typ: (...es: Expr[]) => ({
			type: 'object',
			properties: Object.assign(
				{},
				...es.map((e) =>
					'properties' in e.typ ? e.typ.properties : undefined
				)
			),
		}),
		argsHook: typVHookOOOs,
		callHook: lenVHookOOOs,
	},
};
// alias
// objectOps['MERGE'] = objectOps['MERGEOBJECTS'];

export default objectOps;
export { mergeObjects };
