import {
	Expr,
	FuncDict,
	Scope,
	FuncScope,
	ArrayScope,
	ObjectScope,
	Typ,
	Func,
	LetScope,
	Loc,
} from '../types';
import {
	intersectTyp,
	isVarVal,
	isValidVarKey,
	RESERVED_SUFFIX,
	KeyError,
	ExprTypError,
	isSuperTyp,
} from '../utils';

import Debug from 'debug';
const debug = Debug('formula-to-mongodb-exp:parse');

export function parseExpr_NUMBER(val: number): Expr {
	const typ: Typ = { type: 'number' };
	return { typ, val };
}

export function parseExpr_STRING(
	val: string,
	loc: Loc,
	scopeStack: Scope[],
	literalFuncKey: string = 'LITERAL'
): Expr {
	const scopeStackLength = scopeStack.length;
	const { name } = scopeStack[scopeStackLength - 1];
	if (name !== literalFuncKey && val.startsWith('$'))
		throw new KeyError({
			key: val,
			keyType: 'variable',
			loc,
			reason: `String must not start with special character "$". Consider using ${literalFuncKey} function.`,
		});
	const typ: Typ = { type: 'string' };
	return { typ, val };
}

export function parseExpr_BOOLEAN(val: boolean): Expr {
	const typ: Typ = { type: 'boolean' };
	return { typ, val };
}

export function parseExpr_NULL(val: null): Expr {
	const typ: Typ = { type: 'any' };
	return { typ, val };
}

export function parseVar(varKey: string, varKeyloc: Loc): string {
	if (!isValidVarKey(varKey))
		throw new KeyError({
			key: varKey,
			keyType: 'variable',
			loc: varKeyloc,
			reason: `Variable key must start with lowercase character and not end with ${RESERVED_SUFFIX}.`,
		});
	return varKey;
}

export function parseExpr_var(
	varKey: string,
	scopeStack: Scope[],
	dependencies: string[]
): Expr {
	const scopeStackLength = scopeStack.length;
	for (let i = scopeStackLength - 1; i >= 0; --i) {
		const { name, schema } = scopeStack[i];
		if (schema.hasOwnProperty(varKey)) {
			const typ = { ...schema[varKey] };
			if (name === 'ROOT') {
				if (dependencies.indexOf(varKey) === -1)
					dependencies.push(varKey);
				return { typ, val: '$' + varKey };
			}
			return { typ, val: '$$' + varKey };
		}
	}
	if (dependencies.indexOf(varKey) === -1) dependencies.push(varKey);
	return { typ: { type: 'any' }, val: '$' + varKey };
}

export function parseFUNC_START(
	funcKey: string,
	funcKeyLoc: Loc,
	scopeStack: Scope[],
	funcDict: FuncDict
): void {
	const _funcKey = funcKey.toUpperCase();
	if (!funcDict.hasOwnProperty(_funcKey))
		throw new KeyError({
			key: _funcKey,
			keyType: 'function',
			loc: funcKeyLoc,
			reason: 'Function is not found.',
		});
	const func = funcDict[_funcKey];
	const scopeStackLength = scopeStack.length;
	const newScope = {
		name: _funcKey,
		schema: {},
		func,
		args: [],
		argsLoc: [],
	};
	const argsHook = func.argsHook;
	if (argsHook) argsHook(newScope);
	debug(`START SCOPE ${_funcKey} (${scopeStackLength})`);
	scopeStack.push(newScope);
}

export function parseARGS(expr: Expr, exprLoc: Loc, scopeStack: Scope[]): void {
	const scopeStackLength = scopeStack.length;
	const scope = scopeStack[scopeStackLength - 1];
	if ('func' in scope) {
		const funcScope: FuncScope = scope;
		const {
			func: { argsHook },
			args,
			argsLoc,
		} = funcScope;
		args.push(expr);
		argsLoc.push(exprLoc);
		if (argsHook) argsHook(scope);
	}
}

export function callFunc(
	funcScope: FuncScope,
	useArgsHook: boolean = true,
	useCallHook: boolean = true
): Expr {
	const { func, args } = funcScope;
	const { argsHook, callHook } = func;
	if ((useArgsHook && argsHook) || (useCallHook && callHook)) {
		if (useArgsHook && argsHook) argsHook(funcScope);
		if (useCallHook && callHook) callHook(funcScope);
	}
	return { typ: func.typ(...args), val: func.val(...args) };
}

export function parseFUNC_CALL(scopeStack: Scope[]): Expr {
	const scopeStackLength = scopeStack.length;
	const scope = scopeStack[scopeStackLength - 1];
	let expr: Expr = { typ: { type: 'undetermined' }, val: undefined };
	const name = scope.name;
	if ('func' in scope) {
		const funcScope: FuncScope = scope;
		expr = callFunc(funcScope, false, true);
	}
	debug(`END SCOPE ${name} (${scopeStackLength - 1})`);
	scopeStack.pop();
	return expr;
}

export function parseLET_START(): LetScope {
	const newScope: LetScope = {
		name: 'LET',
		schema: {},
		varVals: {},
	};
	return newScope;
}

export function parseASSIGN(
	varKey: string,
	expr: Expr,
	varKeyLoc: Loc,
	newScope: Scope
): void {
	if ('varVals' in newScope) {
		const newLetScope: LetScope = newScope;
		const { schema, varVals } = newLetScope;
		const { typ, val } = expr;
		if (schema.hasOwnProperty(varKey))
			throw new KeyError({
				key: varKey,
				keyType: 'variable',
				loc: varKeyLoc,
				reason: 'Variable key has been already used in this scope.',
			});
		schema[varKey] = typ;
		varVals[varKey] = val;
	}
}

export function parseLET_STMT(newScope: Scope, scopeStack: Scope[]): void {
	const scopeStackLength = scopeStack.length;
	debug(`START SCOPE LET (${scopeStackLength})`);
	scopeStack.push(newScope);
}

export function parseLET_END(
	letCount: number,
	inExpr: Expr,
	scopeStack: Scope[]
): Expr {
	debug(`parseLET_END: letCount ${letCount}`);
	const { typ, val: inVal } = inExpr;
	let val = inVal;
	for (let i = letCount - 1; i >= 0; --i) {
		const scopeStackLength = scopeStack.length;
		const scope = scopeStack[scopeStackLength - 1];
		if ('varVals' in scope) {
			const letScope: LetScope = scope;
			const { varVals } = letScope;
			val = { $let: { vars: varVals, in: val } };
		}
		debug(`END SCOPE LET (${scopeStackLength - 1})`);
		scopeStack.pop();
	}
	return { typ, val };
}

export function parseARRAY_START(scopeStack: Scope[]): void {
	const scopeStackLength = scopeStack.length;
	const newScope: ArrayScope = {
		name: 'ARRAY',
		schema: {},
		arrayTyp: { type: 'array' },
		arrayVal: [],
		concatArrayExprs: [],
	};
	debug(`START SCOPE ARRAY (${scopeStackLength})`);
	scopeStack.push(newScope);
}

export function parseARRAY_ELEMS(expr: Expr, scopeStack: Scope[]): void {
	const scopeStackLength = scopeStack.length;
	const scope = scopeStack[scopeStackLength - 1];
	if ('arrayTyp' in scope) {
		const arrayScope: ArrayScope = scope;
		const { arrayTyp, arrayVal } = arrayScope;
		const { typ, val } = expr;
		const itemTyp = arrayTyp.items;
		arrayTyp.items = itemTyp ? intersectTyp(itemTyp, typ) : typ;
		arrayVal.push(val);
	}
}

export function parseARRAY_SPREAD(
	arrayExpr: Expr,
	arrayExprLoc: Loc,
	scopeStack: Scope[]
): void {
	const scopeStackLength = scopeStack.length;
	const scope = scopeStack[scopeStackLength - 1];
	if ('arrayTyp' in scope) {
		const arrayScope: ArrayScope = scope;
		const { name, arrayTyp, arrayVal, concatArrayExprs } = arrayScope;
		const { typ } = arrayExpr;
		if (!isSuperTyp(typ, { type: 'array' }))
			throw new ExprTypError({
				scopeName: name,
				receivedTyp: typ,
				loc: arrayExprLoc,
				expectedTyps: [{ type: 'array' }],
			});
		const baseExpr: Expr = { typ: arrayTyp, val: arrayVal };
		const arrayExprs =
			'items' in baseExpr.typ ? [baseExpr, arrayExpr] : [arrayExpr];
		concatArrayExprs.push(...arrayExprs);
		arrayScope.arrayTyp = { type: 'array' };
		arrayScope.arrayVal = [];
	}
}

export function parseARRAY_END(
	scopeStack: Scope[],
	funcDict: FuncDict,
	concatArraysFuncKey: string = 'CONCATARRAYS'
): Expr {
	const scopeStackLength = scopeStack.length;
	const scope = scopeStack[scopeStackLength - 1];
	let expr: Expr = { typ: { type: 'undetermined' }, val: undefined };
	if ('arrayTyp' in scope) {
		const arrayScope: ArrayScope = scope;
		const { arrayTyp, arrayVal, concatArrayExprs } = arrayScope;
		const baseExpr: Expr = { typ: arrayTyp, val: arrayVal };
		if (concatArrayExprs.length) {
			const _concatArrayExprs =
				'items' in baseExpr.typ
					? [...concatArrayExprs, baseExpr]
					: concatArrayExprs;
			expr = callFunc(
				{
					name: concatArraysFuncKey,
					schema: {},
					func: funcDict[concatArraysFuncKey],
					args: _concatArrayExprs,
					argsLoc: _concatArrayExprs.map(() => null),
				},
				false,
				false
			);
		} else expr = baseExpr;
	}
	debug(`END SCOPE ARRAY (${scopeStackLength - 1})`);
	scopeStack.pop();
	return expr;
}

export function parseOBJECT_START(scopeStack: Scope[]): void {
	const scopeStackLength = scopeStack.length;
	const newScope: ObjectScope = {
		name: 'OBJECT',
		schema: {},
		objectTyp: { type: 'object' },
		objectVal: {},
		mergeObjectExprs: [],
	};
	debug(`START SCOPE OBJECT (${scopeStackLength})`);
	scopeStack.push(newScope);
}

export function parseOBJECT_ENTRIES(
	objectKey: string,
	expr: Expr,
	scopeStack: Scope[]
): void {
	const scopeStackLength = scopeStack.length;
	const scope = scopeStack[scopeStackLength - 1];
	if ('objectTyp' in scope) {
		const objectScope: ObjectScope = scope;
		const { objectTyp, objectVal } = objectScope;
		const { typ, val } = expr;
		if (objectTyp.properties) objectTyp.properties[objectKey] = typ;
		else objectTyp.properties = { [objectKey]: typ };
		objectVal[objectKey] = val;
	}
}

export function parseOBJECT_SPREAD(
	objectExpr: Expr,
	objectExprLoc: Loc,
	scopeStack: Scope[]
): void {
	const scopeStackLength = scopeStack.length;
	const scope = scopeStack[scopeStackLength - 1];
	if ('objectTyp' in scope) {
		const objectScope: ObjectScope = scope;
		const { name, objectTyp, objectVal, mergeObjectExprs } = objectScope;
		const { typ } = objectExpr;
		if (!isSuperTyp(typ, { type: 'object' }))
			throw new ExprTypError({
				scopeName: name,
				receivedTyp: typ,
				expectedTyps: [{ type: 'object' }],
				loc: objectExprLoc,
			});
		const baseExpr: Expr = { typ: objectTyp, val: objectVal };
		const objectExprs =
			'properties' in baseExpr.typ
				? [baseExpr, objectExpr]
				: [objectExpr];
		mergeObjectExprs.push(...objectExprs);
		objectScope.objectTyp = { type: 'object' };
		objectScope.objectVal = {};
	}
}

export function parseOBJECT_END(
	scopeStack: Scope[],
	funcDict: FuncDict,
	mergeObjectsFuncKey: string = 'MERGEOBJECTS'
): Expr {
	const scopeStackLength = scopeStack.length;
	const scope = scopeStack[scopeStackLength - 1];
	let expr: Expr = { typ: { type: 'undetermined' }, val: undefined };
	if ('objectTyp' in scope) {
		const objectScope: ObjectScope = scope;
		const { objectTyp, objectVal, mergeObjectExprs } = objectScope;
		const baseExpr: Expr = { typ: objectTyp, val: objectVal };
		if (mergeObjectExprs.length) {
			const _mergeObjectExprs =
				'properties' in baseExpr.typ
					? [...mergeObjectExprs, baseExpr]
					: mergeObjectExprs;
			expr = callFunc(
				{
					name: mergeObjectsFuncKey,
					schema: {},
					func: funcDict[mergeObjectsFuncKey],
					args: _mergeObjectExprs,
					argsLoc: _mergeObjectExprs.map(() => null),
				},
				false,
				false
			);
		} else expr = baseExpr;
	}
	debug(`END SCOPE OBJECT (${scopeStackLength - 1})`);
	scopeStack.pop();
	return expr;
}

export function parseOBJECT_ACCESS(
	objectExpr: Expr,
	objectPropertyKey: string,
	objectExprLoc: Loc,
	objectPropertyKeyLoc: Loc
): Expr {
	const { typ, val } = objectExpr;
	if (!isSuperTyp(typ, { type: 'object' }))
		throw new ExprTypError({
			receivedTyp: typ,
			expectedTyps: [{ type: 'object' }],
			loc: objectExprLoc,
		});

	let _typ: Typ = { type: 'undetermined' };
	if ('properties' in typ) {
		const properties = typ.properties;
		if (!properties || !properties.hasOwnProperty(objectPropertyKey))
			throw new KeyError({
				key: objectPropertyKey,
				keyType: 'objectProperty',
				loc: objectPropertyKeyLoc,
				reason: 'Object property is not found.',
			});
		_typ = properties[objectPropertyKey];
	} else if (typ.type === 'any') _typ = { type: 'any' };

	if (isVarVal(val)) {
		return {
			typ: _typ,
			val: `${val}.${objectPropertyKey}`,
		};
	} else if (typeof val === 'object') {
		if ('$let' in val) {
			const { vars: _vars, in: _in } = val.$let;
			if (
				_vars.hasOwnProperty('object___') &&
				typeof _in === 'string' &&
				_in.startsWith('$$object___')
			)
				return {
					typ: _typ,
					val: {
						$let: {
							vars: { object___: _vars['object___'] },
							in: `${_in}.${objectPropertyKey}`,
						},
					},
				};
		}
	}
	return {
		typ: _typ,
		val: {
			$let: {
				vars: { object___: val },
				in: `$$object___.${objectPropertyKey}`,
			},
		},
	};
}

export function parseAS_TYP(
	expr: Expr,
	typKey:
		| 'string'
		| 'number'
		| 'boolean'
		| 'date'
		| 'array'
		| 'object'
		| 'undetermined'
		| 'any'
): Expr {
	return { typ: { type: typKey }, val: expr.val };
}
