import { booleanTyp, numberTyp, typVHookAnyAny, lenVHookAnyAny } from './utils';
import { FuncDict, Expr } from 'src/types';

// const Any2 = [{ type: 'any' }, { type: 'any' }];

// OpsVal
const cmp = (v1: any, v2: any) => ({ $cmp: [v1, v2] });
const gt = (v1: any, v2: any) => ({ $gt: [v1, v2] });
const lt = (v1: any, v2: any) => ({ $lt: [v1, v2] });
const gte = (v1: any, v2: any) => ({ $gte: [v1, v2] });
const lte = (v1: any, v2: any) => ({ $lte: [v1, v2] });
const eq = (v1: any, v2: any) => ({ $eq: [v1, v2] });
const ne = (v1: any, v2: any) => ({ $ne: [v1, v2] });

const comparisonOps: FuncDict = {
	CMP: {
		val: (e0: Expr, e1: Expr) => cmp(e0.val, e1.val),
		typ: numberTyp,
		argsHook: typVHookAnyAny,
		callHook: lenVHookAnyAny,
	},
	GT: {
		val: (e0: Expr, e1: Expr) => gt(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAnyAny,
		callHook: lenVHookAnyAny,
	},
	LT: {
		val: (e0: Expr, e1: Expr) => lt(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAnyAny,
		callHook: lenVHookAnyAny,
	},
	GTE: {
		val: (e0: Expr, e1: Expr) => gte(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAnyAny,
		callHook: lenVHookAnyAny,
	},
	LTE: {
		val: (e0: Expr, e1: Expr) => lte(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAnyAny,
		callHook: lenVHookAnyAny,
	},
	EQ: {
		val: (e0: Expr, e1: Expr) => eq(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAnyAny,
		callHook: lenVHookAnyAny,
	},
	NE: {
		val: (e0: Expr, e1: Expr) => ne(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAnyAny,
		callHook: lenVHookAnyAny,
	},
};

export default comparisonOps;
export { cmp, gt, gte, lt, lte, eq, ne };
