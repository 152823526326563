import {
	numberTyp,
	booleanTyp,
	arg0Typ,
	arg0ItemTyp,
	numberArrayTyp,
	typVHookNNNo,
	typVHookA,
	typVHookAAAs,
	typVHookAN,
	typVHookAAnyNoNo,
	typVHookAny,
	typVHookAnyA,
	typVHookANNo,
	typVHookASB,
	typVHookASAny,
	lenVHookNNNo,
	lenVHookA,
	lenVHookAAAs,
	lenVHookAN,
	lenVHookAAnyNoNo,
	lenVHookAny,
	lenVHookAnyA,
	lenVHookANNo,
	lenVHookASB,
	lenVHookASAny,
} from './utils';
import { subtract, trunc } from './arithmeticOps';
import { cond } from './conditionalOps';
import { gt, gte } from './comparisonOps';
import { min, max } from './accumulatorOps';
import { Expr, Typ, FuncDict, FuncScope } from 'src/types';
import {
	intersectTyp,
	isValidVarKey,
	RESERVED_SUFFIX,
	ExprValError,
	KeyError,
} from '../utils';

// OpsVal
const arrayElemAt = (v0: any, v1: any) => ({ $arrayElemAt: [v0, v1] });
const concatArrays = (...vs: any[]) => {
	const _vs: any[] = [];
	vs.forEach((v) => {
		if (v !== null && v.hasOwnProperty('$concatArrays'))
			_vs.push(...v.$concatArrays);
		else _vs.push(v);
	});
	return { $concatArrays: [..._vs] };
};
const filter = (v0: any, v1: any, v2: any) => ({
	$filter: { input: v0, as: v1, cond: v2 },
});
const _in = (v0: any, v1: any) => ({ $in: [v0, v1] });
const indexOfArray = (v0: any, v1: any, v2?: any, v3?: any) => ({
	$indexOfArray: [v0, v1, v2, v3].filter((v) => v !== undefined),
});
const isArray = (v: any) => ({ $isArray: v });
const map = (v0: any, v1: any, v2: any) => ({
	$map: { input: v0, as: v1, in: v2 },
});
const range = (v0: any, v1: any, v2?: any) => ({
	$range: [v0, v1, v2].filter((v) => v !== undefined),
});
const reverseArray = (v: any) => ({ $reverseArray: v });
const size = (v: any) => ({ $size: v });
const slice = (v0: any, v1: any, v2?: any) => ({
	$slice: [v0, v1, v2].filter((v) => v !== undefined),
});
const zip = (...vs: any[]) => ({ $zip: { inputs: [...vs] } });
const advanceArraySlice = (v0: any, v1: any, v2: any) => {
	const sliceInV = {
		$let: {
			vars: {
				absPos1___: cond(
					gte('$$pos1___', 0),
					min(['$$pos1___', '$$len___']),
					max([{ $add: ['$$len___', '$$pos1___'] }, 0])
				),
				absPos2___: cond(
					gte('$$pos2___', 0),
					min(['$$pos2___', '$$len___']),
					max([{ $add: ['$$len___', '$$pos2___'] }, 0])
				),
			},
			in: cond(
				gt('$$absPos2___', '$$absPos1___'),
				slice(
					'$$arr___',
					'$$absPos1___',
					subtract('$$absPos2___', '$$absPos1___')
				),
				[]
			),
		},
	};
	return {
		$let: {
			vars: { arr___: v0 },
			in: {
				$let: {
					vars: { len___: size('$$arr___') },
					in: {
						$let: {
							vars: {
								pos1___: trunc(v1),
								pos2___:
									v2 !== undefined ? trunc(v2) : '$$len___',
							},
							in: sliceInV,
						},
					},
				},
			},
		},
	};
};

// argsHook
const _mapFilterArgsHook = (funcScope: FuncScope): void => {
	const { name, args, argsLoc, schema } = funcScope;
	const argsLength = args.length;
	if (argsLength === 2) {
		const [arg0, arg1] = args;
		const { typ: t0 } = arg0;
		const { val: v1 } = arg1;
		if (typeof v1 !== 'string')
			throw new ExprValError({
				scopeName: name,
				index: 1,
				receivedVal: v1,
				reason: 'Type of expr val must be string.',
				loc: argsLoc[1],
			});
		if (!isValidVarKey(v1))
			throw new KeyError({
				key: v1,
				keyType: 'variable',
				reason: `Variable key must start with lowercase character and not end with ${RESERVED_SUFFIX}.`,
				loc: argsLoc[1],
			});
		if ('items' in t0 && t0.items) schema[v1] = t0.items;
		else schema[v1] = { type: 'undetermined' };
	}
};

const arrayOps: FuncDict = {
	ARRAYELEMAT: {
		val: (e0: Expr, e1: Expr) => arrayElemAt(e0.val, e1.val),
		typ: arg0ItemTyp,
		argsHook: typVHookAN,
		callHook: lenVHookAN,
	},
	CONCATARRAYS: {
		val: (...es: Expr[]) => concatArrays(...es.map((e) => e.val)),
		typ: (...es: Expr[]) =>
			es.reduce((t: Typ, e: Expr) => intersectTyp(t, e.typ), {
				type: 'array',
			}),
		argsHook: typVHookAAAs,
		callHook: lenVHookAAAs,
	},
	FILTER: {
		val: (e0: Expr, e1: Expr, e2: Expr) => filter(e0.val, e1.val, e2.val),
		typ: arg0Typ,
		argsHook: (funcScope: FuncScope) => {
			typVHookASB(funcScope);
			_mapFilterArgsHook(funcScope);
		},
		callHook: lenVHookASB,
	},
	IN: {
		val: (e0: Expr, e1: Expr) => _in(e0.val, e1.val),
		typ: booleanTyp,
		argsHook: typVHookAnyA,
		callHook: lenVHookAnyA,
	},
	INDEXOFARRAY: {
		val: (e0: Expr, e1: Expr, e2?: Expr, e3?: Expr) =>
			indexOfArray(
				e0.val,
				e1.val,
				e2 ? e2.val : undefined,
				e3 ? e3.val : undefined
			),
		typ: numberTyp,
		argsHook: typVHookAAnyNoNo,
		callHook: lenVHookAAnyNoNo,
	},
	ISARRAY: {
		val: (e: Expr) => isArray(e.val),
		typ: booleanTyp,
		argsHook: typVHookAny,
		callHook: lenVHookAny,
	},
	MAP: {
		val: (e0: Expr, e1: Expr, e2: Expr) => map(e0.val, e1.val, e2.val),
		typ: (e0: Expr, e1: Expr, e2: Expr) => ({
			type: 'array',
			items: e2.typ,
		}),
		argsHook: (funcScope: FuncScope) => {
			typVHookASAny(funcScope);
			_mapFilterArgsHook(funcScope);
		},
		callHook: lenVHookASAny,
	},
	RANGE: {
		val: (e0: Expr, e1: Expr, e2?: Expr) =>
			range(e0.val, e1.val, e2 ? e2.val : undefined),
		typ: numberArrayTyp,
		argsHook: typVHookNNNo,
		callHook: lenVHookNNNo,
	},
	REVERSEARRAY: {
		val: (e: Expr) => reverseArray(e.val),
		typ: arg0Typ,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
	SIZE: {
		val: (e: Expr) => size(e.val),
		typ: numberTyp,
		argsHook: typVHookA,
		callHook: lenVHookA,
	},
	SLICE: {
		val: (e0: Expr, e1: Expr, e2?: Expr) =>
			slice(e0.val, e1.val, e2 ? e2.val : undefined),
		typ: arg0Typ,
		argsHook: typVHookANNo,
		callHook: lenVHookANNo,
	},
	ZIP: {
		// No defaults
		val: (...es: Expr[]) => zip(...es.map((e) => e.val)),
		typ: (...es: Expr[]) => ({
			type: 'array',
			items: es.reduce((t: Typ, e: Expr) => intersectTyp(t, e.typ), {
				type: 'array',
			}),
		}),
		argsHook: typVHookAAAs,
		callHook: lenVHookAAAs,
	},
	ADVANCEARRAYSLICE: {
		val: (e0: Expr, e1: Expr, e2?: Expr) =>
			advanceArraySlice(e0.val, e1.val, e2 ? e2.val : undefined),
		typ: arg0Typ,
		argsHook: typVHookANNo,
		callHook: lenVHookANNo,
	},
};
// alias
arrayOps['REVERSE'] = arrayOps['REVERSEARRAY'];
export default arrayOps;
export {
	arrayElemAt,
	concatArrays,
	filter,
	_in,
	indexOfArray,
	isArray,
	map,
	range,
	reverseArray,
	size,
	slice,
	zip,
	advanceArraySlice,
};
